/* eslint react-hooks/exhaustive-deps: warn */

import { lazy, memo, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Toaster, toast, ToastBar } from "react-hot-toast";
import { createPortal } from "react-dom";
// import ReactGA from "react-ga";
import "react-quill/dist/quill.snow.css";

import Meta from "../meta/Meta";
import SvgFilterDefinitions from "../svgFilterDefinitions/SvgFilterDefinitions";

import ErrorBoundary from "../errorBoundary/ErrorBoundary";
import DsaProgress from "../dsaProgress/DsaProgress";
import CloseButton from "../atoms/closeButton/CloseButton";

import styles from "./App.module.css";
import { ROUTES } from "../../constants";
import Footer from "../Footer/Footer";
import { getOnboarding } from "../../utils/localStoreateUtil";
import { useAuth } from "../../context/Auth";

// const LandingPage = lazy(() => import("../../pages/landingPage/LandingPage"));
const HomeContainer = lazy(() => import("../../pages/home/HomeContainer"));
const LoginContainer = lazy(() => import("../../pages/login/LoginContainer"));
const RegisterContainer = lazy(
  () => import("../../pages/register/RegisterContainer")
);
const SetupTeamContainer = lazy(
  () => import("../../pages/setupTeam/SetupTeamContainer")
);
// const SignupContainer = lazy(
//   () => import("../../pages/signup/SignupContainer")
// );
const ProfilePageContainer = lazy(
  () => import("../../pages/profile/ProfileContainer")
);
const FlashStatContainer = lazy(
  () => import("../../pages/flashstatPlayer/FlashStatPlayerContainer")
);
const FlashStatTeamContainer = lazy(
  () => import("../../pages/flashstatTeam/FlashStatTeamContainer")
);
const EditProfilePageContainer = lazy(
  () => import("../../pages/editProfile/EditProfileContainer")
);
const EditPlayerBioContainer = lazy(
  () => import("../../pages/editPlayerBio/EditPlayerBioContainer")
);
const AddEditPlayerContainer = lazy(
  () => import("../../pages/addEditPlayer/AddEditPlayerContainer")
);
const SocialLinkContainer = lazy(
  () => import("../../pages/socialLink/SocialLinkContainer")
);
const SubscriptionContainer = lazy(
  () => import("../../pages/subscrpiton/SubscriptionContainer")
);
// const EditTeamContainer = lazy(
//   () => import("../../pages/editTeam/EditTeamContainer")
// );
const TeamContainer = lazy(() => import("../../pages/team/TeamContainer"));
// const StoreContainer = lazy(() => import("../../pages/store/StoreContainer"));
const MyTestsContainer = lazy(
  () => import("../../pages/myTests/MyTestsContainer")
);
const TestResultContainer = lazy(
  () => import("../../pages/testResult/TestResultContainer")
);
const ContactUsContainer = lazy(
  () => import("../../pages/contactUs/ContactUsContainer")
);
const ChangePasswordContainer = lazy(
  () => import("../../pages/changePassword/ChangePasswordContainer")
);

const PaymentStatusContainer = lazy(
  () => import("../../pages/paymentStatus/PaymentStatusContainer")
);
const PtiTestContainer = lazy(
  () => import("../../pages/ptiTest/PtiTestContainer")
);
// const RosterContainer = lazy(
//   () => import("../../pages/roster/RosterContainer")
// );
const TeamSignupContainer = lazy(
  () => import("../../pages/teamSignup/TeamSignupContainer")
);
const DataSetsContainer = lazy(
  () => import("../../pages/dataSets/DataSetsContainer")
);

const AnalysisContainer = lazy(
  () => import("../../pages/analysis/AnalysisContainer")
);
const SubmissionsContainer = lazy(
  () => import("../../pages/submissions/SubmissionsContainerWrapper")
);
// const SubmissionContainer = lazy(
//   () => import("../../pages/submission/SubmissionContainer")
// );
const DataCollectContainer = lazy(
  () => import("../../pages/dataCollect/DataCollectContainer")
);
const EditSubmissionContainer = lazy(
  () => import("../../pages/editSubmission/EditSubmissionContainer")
);

const SettingsContainer = lazy(
  () => import("../../pages/settings/SettingsContainer")
);

const LineUpContainer = lazy(
  () => import("../../pages/lineup/LineUpContainer")
);

const ScorecardContainer = lazy(
  () => import("../../pages/scorecard/ScorecardContainer")
);
const ResetPasswordContainer = lazy(
  () => import("../../pages/login/ResetPasswordContainer")
);
const PrivacyPolicyContainer = lazy(
  () => import("../../pages/privacyPolicy/PrivacyPolicyContainer")
);
const PageNotFound = lazy(
  () => import("../../pages/pageNotFound/PageNotFound")
);

const OnboardingContainer = lazy(
  () => import("../../pages/onboarding/OnboardingContainer")
);
const IntegrationsContainer = lazy(
  () => import("../../pages/integrations/IntegrationsContainer")
);
const DiscoveryContainer = lazy(
  () => import("../../pages/discovery/DiscoveryContainer")
);

const NotificationsContainer = lazy(
() => import("../../pages/notifications/NotificationsContainer")
);

const FollowingContainer = lazy(
  () => import("../../pages/following/FollowingContainer")
);

function App() {
  const { isReady, isManager, isParent, isPlayer } = useAuth();
  // const { pathname } = useLocation();

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     ReactGA.pageview(pathname);
  //   }, 300);

  //   return () => {
  //     clearTimeout(timeout);
  //   }
  // }, [pathname]);
  const isOnboarded = isReady ? getOnboarding(isManager, isParent, isPlayer) : true;


  return (
    <>
      {createPortal(
        <Toaster
          toastOptions={{
            className: styles.toasterContainer,
            duration: 3600,
            success: {
              iconTheme: {
                primary: "var(--success)",
                secondary: "var(--primaryBlack)",
              },
            },
            error: {
              iconTheme: {
                primary: "var(--error)",
                secondary: "var(--primaryBlack)",
              },
            },
            loading: {
              iconTheme: {
                primary: "var(--secondaryBlue)",
                secondary: "var(--primaryBlack)",
              },
            },
          }}
        >
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                  {t.type !== "loading" && (
                    <CloseButton
                      className={styles.close}
                      handleClose={() => toast.dismiss(t.id)}
                    />
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>,
        document.body
      )}
      <Meta />
      <SvgFilterDefinitions />
      <div className={styles.app}>
        <div className={styles.appWrapper} id="appWrapper">
          <ErrorBoundary>
            <Suspense
              fallback={
                <div className={styles.pageLoader}>
                  <DsaProgress />
                </div>
              }
            >
              {!isOnboarded? (
                <OnboardingContainer />
              ) : (
                <Routes>
                  <Route path={ROUTES.FORGOT_PASSWORD}>
                    <Route path=":teamId/:role" element={<LoginContainer />} />
                    <Route path="" element={<LoginContainer />} />
                  </Route>

                  <Route
                    path={`${ROUTES.RESET_PASSWORD}/:id`}
                    element={<ResetPasswordContainer />}
                  />

                  <Route path={ROUTES.LOGIN}>
                    <Route path=":teamId/:role" element={<LoginContainer />} />
                    <Route path="" element={<LoginContainer />} />
                  </Route>
                  <Route path={ROUTES.REGISTER}>
                    <Route
                      path=":teamId/:role"
                      element={<RegisterContainer />}
                    />
                    <Route path="" element={<RegisterContainer />} />
                  </Route>

                  <Route path={ROUTES.SETUP_TEAM}>
                    <Route
                      path={`${ROUTES.SETUP_TEAM}/:tab`}
                      element={<SetupTeamContainer />}
                    />
                    <Route path="" element={<SetupTeamContainer />} />
                  </Route>
                  <Route path={ROUTES.FLASHSTAT}>
                    <Route path=":playerId" element={<FlashStatContainer />} />
                    <Route path="" element={<FlashStatContainer />} />
                  </Route>
                  <Route path={ROUTES.FLASHSTATTEAM}>
                    <Route path=":teamId" element={<FlashStatTeamContainer />} />
                    <Route path="" element={<FlashStatTeamContainer />} />
                  </Route>

                  <Route path={ROUTES.PROFILE}>
                    <Route path=":tab" element={<ProfilePageContainer />} />
                    <Route
                      path=":tab/:playerId"
                      element={<ProfilePageContainer />}
                    />
                    <Route
                      path=":tab/:playerId/:teamId"
                      element={<ProfilePageContainer />}
                    />
                    <Route path={""} element={<ProfilePageContainer />} />
                  </Route>
                  <Route path={ROUTES.UPLOAD_CLIP}>
                    <Route
                      path="new/:playerId"
                      element={<ProfilePageContainer />}
                    />
                    <Route
                      path="edit/:playerId/:clipId"
                      element={<ProfilePageContainer />}
                    />
                    <Route path={""} element={<ProfilePageContainer />} />
                  </Route>

                  <Route path={ROUTES.EDIT_PROFILE}>
                    <Route path={""} element={<EditProfilePageContainer />} />
                  </Route>
                  <Route path={ROUTES.ADD_EDIT_PLAYER}>
                    <Route
                      path="editPlayer/:playerId"
                      element={<AddEditPlayerContainer />}
                    />
                    <Route
                      path=":teamId"
                      element={<AddEditPlayerContainer />}
                    />
                    <Route
                      path=":teamId/:playerId"
                      element={<AddEditPlayerContainer />}
                    />
                    <Route path={""} element={<AddEditPlayerContainer />} />
                  </Route>

                  <Route
                    path={`${ROUTES.EDIT_BIO}/:playerId`}
                    element={<EditPlayerBioContainer />}
                  />
                  <Route
                    path={ROUTES.LINK_MEDIA}
                    element={<SocialLinkContainer />}
                  />
                  <Route
                    path={`${ROUTES.LINK_MEDIA}/:media`}
                    element={<SocialLinkContainer />}
                  />
                  <Route path={ROUTES.SUBSCRIPTION}>
                    <Route
                      path="player/:playerId"
                      element={<SubscriptionContainer />}
                    />
                    <Route
                      path="team/:teamId"
                      element={<SubscriptionContainer />}
                    />
                    <Route path={""} element={<SubscriptionContainer />} />
                  </Route>
                  <Route
                    path={ROUTES.CONTACT_US}
                    element={<ContactUsContainer />}
                  />
                  <Route
                    path={ROUTES.CHANGE_PASS}
                    element={<ChangePasswordContainer />}
                  />
                  <Route path={ROUTES.TEAMS}>
                    <Route path=":teamId" element={<TeamContainer />} />
                    <Route path=":teamId/:tab" element={<TeamContainer />} />
                  </Route>
                  <Route path={ROUTES.MY_TEST}>
                    <Route
                      path=":tab/:teamId/:sId"
                      element={<MyTestsContainer />}
                    />
                    <Route path=":tab" element={<MyTestsContainer />} />
                    <Route path={""} element={<MyTestsContainer />} />
                  </Route>
                  <Route
                    path={`${ROUTES.PAYMENT_STATUS}/:id/:pCode`}
                    element={<PaymentStatusContainer />}
                  />
                  <Route path={`${ROUTES.TEST_RESULT}/:testId`}>
                    <Route path=":playerId" element={<TestResultContainer />} />
                    <Route path="" element={<TestResultContainer />} />
                  </Route>
                  <Route path={`${ROUTES.PTI_TEST}/:testId`}>
                    <Route path=":tab" element={<PtiTestContainer />} />
                    <Route path="" element={<PtiTestContainer />} />
                  </Route>

                  <Route path={ROUTES.TEAM_SIGNUP}>
                    <Route
                      path=":path/:teamId"
                      element={<TeamSignupContainer />}
                    />
                    <Route
                      path=":path/:teamId/:tab"
                      element={<TeamSignupContainer />}
                    />
                    <Route path="" element={<TeamSignupContainer />} />
                  </Route>

                  <Route path={`${ROUTES.DATA_SETS}`}>
                    <Route
                      path="team/:teamId"
                      element={<DataSetsContainer />}
                    />
                    <Route
                      path="player/:playerId"
                      element={<DataSetsContainer />}
                    />
                    <Route path="" element={<DataSetsContainer />} />
                  </Route>
                  <Route path={`${ROUTES.ANALYSIS}`}>
                    <Route path=":teamId" element={<AnalysisContainer />} />
                    <Route
                      path=":teamId/:tab"
                      element={<AnalysisContainer />}
                    />
                    <Route path="" element={<AnalysisContainer />} />
                  </Route>

                  <Route
                    path={ROUTES.SETTINGS}
                    element={<SettingsContainer />}
                  />
                  <Route path={`${ROUTES.DATA_COLLECT}`}>
                    <Route
                      path=":scoreCardId"
                      element={<DataCollectContainer />}
                    />
                    <Route
                      path=":scoreCardId/:teamId"
                      element={<DataCollectContainer />}
                    />
                  </Route>
                  <Route path={`${ROUTES.SUBMISSIONS}`}>
                    <Route path="view">
                      <Route
                        path=":submissionId"
                        element={<SubmissionsContainer />}
                      />
                      <Route
                        path=":submissionId/goals/:metricId"
                        element={<SubmissionsContainer />}
                      />
                      <Route
                        path=":submissionId/goals/:metricId/:goalTab"
                        element={<SubmissionsContainer />}
                      />
                      <Route
                        path=":submissionId/:tab"
                        element={<SubmissionsContainer />}
                      />
                      <Route
                        path=":submissionId/:tab/:playerId"
                        element={<SubmissionsContainer />}
                      />
                    </Route>

                    <Route
                      path="edit/:submissionId"
                      element={<EditSubmissionContainer />}
                    />

                    <Route path=":listTab" element={<SubmissionsContainer />} />
                    <Route
                      path=":listTab/:playerId"
                      element={<SubmissionsContainer />}
                    />

                    <Route path="" element={<SubmissionsContainer />} />
                  </Route>

                  <Route path={ROUTES.SCORECARD}>
                    <Route
                      path=":scoreCardId"
                      element={<ScorecardContainer />}
                    />
                    <Route
                      path=":scoreCardId/:tab"
                      element={<ScorecardContainer />}
                    />
                    <Route path={""} element={<ScorecardContainer />} />
                  </Route>
                  <Route
                    path={`${ROUTES.EDIT_SUBMISSION}/:submissionId`}
                    element={<EditSubmissionContainer />}
                  />
                  <Route path={ROUTES.HOME}>
                    <Route path=":teamId" element={<HomeContainer />} />
                    <Route path=":teamId/:tab" element={<HomeContainer />} />
                    <Route
                      path=":teamId/:tab/:subTab"
                      element={<HomeContainer />}
                    />
                    <Route path="" element={<HomeContainer />} />
                    {/* <Route path="" element={<LandingPage />} /> */}
                  </Route>
                  <Route path={ROUTES.SEARCH}>
                    <Route path=":tab" element={<DiscoveryContainer />} />
                    <Route path="" element={<DiscoveryContainer />} />
                  </Route>
                  <Route path={ROUTES.FOLLOWING}>
                    <Route path=":tab" element={<FollowingContainer />} />
                    <Route path="" element={<FollowingContainer />} />
                  </Route>
                  <Route
                    path={`${ROUTES.LINEUP}/:lineupId`}
                    element={<LineUpContainer />}
                  />
                  <Route
                    path={ROUTES.PRIVACY_POLICY}
                    element={<PrivacyPolicyContainer />}
                  />
                  <Route
                    path={ROUTES.INTEGRATIONS}
                    element={<IntegrationsContainer />}
                  />
                  <Route
                    path={ROUTES.NOTIFICATIONS}
                    element={<NotificationsContainer />}
                  />
                  <Route path={ROUTES.NOT_FOUND} element={<PageNotFound />} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              )}
            </Suspense>
          </ErrorBoundary>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default memo(App);
